import React, { useState } from "react";
import {
  Row, Col, Accordion, Card
} from "react-bootstrap";
import {
  mediaListAccordion, content,
  category, accordionCard, cardHeader,
  mlTitle, accordionTitle, accordion,imgCol, accordionDescription,
  productImage, accordionIcon, accordionList, accordionIconOpen,
  mediaListAccordionReverse, showDate, colContent
} from "./style.mod.scss";
import {  ChevronDown } from "react-bootstrap-icons";
import getImagePath from "helpers/get-image-path";
import RichText from "components/rich-text";

const MediaListAccordion = ({ data }) => {
  const [open, setOpen] = useState({});

  const { title, subtitle, image, timeline } = data;
  let alternate = true;

  return (
    <div className={mediaListAccordion}>
      <Row className={`${(alternate) ? mediaListAccordionReverse : "" }`}>
        <Col sm="12" md="6" lg="6" className={imgCol}>
          <img className={productImage} srcSet={getImagePath(image, true)} alt={image?.alt}>
          </img>
        </Col>
        <Col  sm="12" md="6" lg="6" className={colContent}>
          <div className={content}>
            <h2 className={category}>{title}</h2>
            <h1 className={mlTitle}>{subtitle}</h1>
            <ul>
              {timeline.map((ac, index)=>(
                <li key={index}> 
                  <span className={accordionList}>{index+1}</span>
                  <Accordion className={`${accordion} pointer`} key={ac.id}>
                    <Card className={accordionCard}>
                      <Accordion.Toggle as={Card.Header} className={cardHeader} eventKey={ac._key} onClick={() => setOpen(open === ac._key ? null : ac._key)}>
                        <p className={`${(alternate) ? showDate : "noDate" }`} >{ac.timelineDate}</p>
                        <p className={accordionTitle}>{ac.timelineTitle}</p>
                        <span className={`${accordionIcon} ${ac._key !== open ? "" : accordionIconOpen}`}>
                          <ChevronDown/>
                        </span>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={ac._key}>
                        <Card.Body className={accordionDescription}>
                          <RichText blocks={ac.timelineText}/>  
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MediaListAccordion;
